<template>
  <v-container class="fill-height">
    <div>
      <div>...Подождите немного</div>
    </div>
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
export default {
  name: "PageAuth",
  data() {
    return {
      pending: true,
      error: "",
    };
  },
  metaInfo: {
    title: "Авторизация",
  },
  created() {
    this.authorize();
  },
  methods: {
    async authorize() {
      const { code } = this.$route.query;
      if (!code) {
        this.pending = false;
        this.error = `Отсутствует параметр code. Пожалуйста, сообщите нам об этой ошибке`;
        return;
      }
      this.pending = true;
      const { data } = await apiClient({
        method: "GET",
        url: "/auth/complete",
        params: {
          code,
        },
      });
      await this.$store.dispatch("user/setAuth", data);
      const nextUrl = localStorage?.getItem("next_redirect");
      if (nextUrl) {
        localStorage?.removeItem("next_redirect");
        this.$router.push(nextUrl);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style></style>
